@import '../../../styles/var';

.actual {
    position: relative;
    padding-bottom: 180px;

    @media(max-width: $md4+px) {
        padding-bottom: 0;
    }

    .title {
        position: relative;
        z-index: 5;
        color: #FF314C;
        font-family: $mont;
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        display: flex;
        flex-direction: column;

        @media(max-width: $md4+px) {
            font-size: 40px;
            display: block;
        }

        @media(max-width: $md6+px) {
            font-size: 20px;
        }

        span {
            color: #fff;
            font-size: 45px;
            line-height: normal;

            @media(max-width: $md4+px) {
                font-size: 40px;
                margin-top: 0;
                color: #FF314C;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }
    }

    .list {
        display: grid;

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 30px;
        }

        .item {
            width: 568px;

            @media(max-width: $md4+px) {
                margin: 0 !important;
                text-align: left !important;
                max-width: 400px;
                width: 100%;
            }

            .text {
                position: relative;
                z-index: 10;

                @media(max-width: $md4+px) {
                    margin: 0 !important;
                }
            }

            .line {
                position: relative;
                z-index: 3;
                width: 100%;

                @media(max-width: $md4+px) {
                    margin: 8px 0 0 !important;
                }
            }

            &:nth-child(1) {
                margin-left: auto;
                text-align: right;

                .text {
                    margin-right: 150px;
                }

                .line {
                    margin-top: 10px;
                }
            }

            &:nth-child(2) {
                margin-top: 30px;

                .line {
                    margin-top: -20px;
                }
            }

            &:nth-child(3) {
                margin-left: auto;
                text-align: right;
                margin-top: 130px;

                .line {
                    margin-top: 10px;
                }
            }

            &:nth-child(4) {
                margin-top: 35px;

                .text {
                    margin-left: 170px;
                }

                .line {
                    margin-top: -20px;
                }
            }
        }
    }

    .image {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 488px;

        @media(max-width: $md3+px) {
            width: 400px;
            top: unset;
            bottom: 0;
        }

        @media(max-width: $md4+px) {
            left: unset;
            margin: 0;
            width: 300px;
            bottom: -50px;
        }

        @media(max-width: $md5+px) {
            width: 250px;
            right: -50px;
        }

        @media(max-width: $md6+px) {
            width: 150px;
            right: -20px;
            bottom: -70px;
        }
    }
}