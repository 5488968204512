@import '../../../styles/var';

.advantages {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 60px;

    @media(max-width: $md3+px) {
        align-items: flex-end;
    }

    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: unset;
        gap: 0;
    }

    .content {
        width: 525px;

        @media(max-width: $md3+px) {
            width: 500px;
        }   

        @media(max-width: $md4+px) {
            width: 100%;
        }

        .title {
            color: #FF314C;
            font-family: $mont;
            font-size: 100px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            display: flex;
            flex-direction: column;
    
            @media(max-width: $md4+px) {
                font-size: 30px;
                display: block;
            }
    
            @media(max-width: $md6+px) {
                font-size: 20px;
            }
    
            span {
                font-size: 40px;
                line-height: normal;
    
                @media(max-width: $md4+px) {
                    font-size: 30px;
                    margin-top: 0;
                }
    
                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
        }

        .textSwiperWrap {
            margin: 50px 0;

            @media(max-width: $md4+px) {
                margin: 40px 0 20px;
            }

            .textCard {
                display: flex;
                flex-direction: column;
                gap: 15px;

                h4 {
                    font-weight: 400;
                }
            }
        }

        .btn {
            @media(max-width: $md4+px) {
                display: none;
            }
        }
    }

    .swiperWrap {
        position: relative;
        width: 438px;
        margin-top: 30px;

        @media(max-width: $md3+px) {
            margin-top: 0;
            width: 340px;
        }

        @media(max-width: $md4+px) {
            margin: 0 auto;
        }

        .nav {
            position: absolute;
            z-index: 5;
            display: flex;
            gap: 36px;
            bottom: 15px;
            right: 20px;

            @media(max-width: $md5+px) {
                position: relative;
                bottom: unset;
                left: unset;
                justify-content: flex-end;
                margin-bottom: 10px;
                right: 0;
            }

            &Btn {
                width: 50px;
                height: 50px;
                background: #C62432;
                border: 1px solid #C62432;
                border-radius: 100%;
                transition: all .3s;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                @media(max-width: $md6+px) {
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    opacity: 0.7;
                }

                &.inactive {
                    border-color: #fff;
                    background: transparent;
                    opacity: 1 !important;
                }

                &.right {
                    transform: rotate(180deg);
                }

                .arrow {
                    width: 30px;

                    @media(max-width: $md6+px) {
                        width: 22px;
                    }
                }
            }
        }

        .status {
            position: absolute;
            z-index: 5;
            top: 0;
            left: -85px;
            color: $white;
            font-family: $mont;
            font-size: 40px;
            font-weight: 800;
            line-height: normal;

            @media(max-width: $md3+px) {
                top: 15px;
                left: 20px;
            }

            @media(max-width: $md4+px) {
                font-size: 32px;
            }

            @media(max-width: $md5+px) {
                left: 0;
                top: 10px;
            }

            @media(max-width: $md6+px) {
                font-size: 24px;
            }

            span {
                color: #FF314C;
            }
        }

        .card {
            width: 100%;

            &Image {
                width: 100%;
            }
        }
    }
}

:global(.itAdvSwiper) {
    overflow: hidden !important;

    @media(max-width: $md6+px) {
        overflow: visible !important;
    }
}

:global(.itAdvSwiperSlide) {
    height: auto !important;
}