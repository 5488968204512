@import '../../../styles/var';

.team {
    position: relative;

    .title {
        @media(max-width: $md3+px) {
            text-align: center;
        }
    }

    .wrap {
        position: relative;

        .textSwiperWrap {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 523px;
            width: 100%;
            max-width: 697px;
            .subCard {

                .subText {

                }
            }
        }

        .nav {
            position: absolute;
            z-index: 5;
            display: flex;
            gap: 36px;
            left: 523px;
            top: 180px;

            @media(max-width: $md3+px) {
                z-index: 10;
                width: 100%;
                justify-content: space-between;
                left: 0;
                top: 250px;
            }

            @media(max-width: $md5+px) {
                position: relative;
                top: unset;
                left: unset;
                margin-top: 20px;
            }

            &Btn {
                width: 70px;
                height: 70px;
                background: #C62432;
                border: 1px solid #C62432;
                border-radius: 100%;
                transition: all .3s;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @media(max-width: $md6+px) {
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    opacity: 0.7;
                }

                &.inactive {
                    border-color: #fff;
                    background: transparent;
                    opacity: 1!important;
                }

                &.right {
                    transform: rotate(180deg);
                }

                .arrow {
                    width: 40px;

                    @media(max-width: $md6+px) {
                        width: 22px;
                    }
                }
            }
        }
    }

    .swiperWrap {
        position: relative;
        width: 279px;
        margin-top: 60px;

        @media(max-width: $md3+px) {
            width: 100%;
            max-width: 488px;
            margin: 60px auto 0;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .cardWrap {
            .cardText {
                display: none;
                margin-top: 40px;

                @media(max-width: $md3+px) {
                    display: block;
                }

                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
            }
        }

        .card {
            transition: all .3s;
            background: #C62230;
            width: 100%;
            padding: 12px 12px 26px;

            color: $white;
            font-family: $mont;

            @media(max-width: $md3+px) {
                padding: 22px 22px 46px !important;
            }

            @media(max-width: $md6+px) {
                padding: 15px 15px 30px !important;
            }

            * {
                transition: all .3s;
            }

            &.active {
                padding: 22px 22px 46px;

                .cardTitle {
                    font-size: 38px;
                    margin: 16px 0 6px;
                }

                .cardSubtitle {
                    font-size: 22px;
                }
            }

            &Image {
                width: 100%;
            }

            &Title {
                font-size: 22px;
                font-weight: 800;
                line-height: normal;
                margin: 9px 0 4px;

                @media(max-width: $md3+px) {
                    font-size: 38px !important;
                    margin: 16px 0 6px !important;
                }

                @media(max-width: $md6+px) {
                    font-size: 26px !important;
                    margin: 11px 0 6px !important;
                }
            }

            &Subtitle {
                font-size: 12px;
                font-weight: 400;
                line-height: normal;

                @media(max-width: $md3+px) {
                    font-size: 22px !important;
                }

                @media(max-width: $md6+px) {
                    font-size: 16px !important;
                }
            }
        }
    }
}

:global(.aboutTeamSwiper) {
    overflow: visible !important;
    height: 635px !important;

    @media(max-width: $md3+px) {
        height: auto !important;
        overflow: hidden !important;
    }

    @media(max-width: $md6+px) {
        overflow: visible !important;
    }
}

:global(.aboutTeamSwiperSlide) {
    height: auto !important;
    width: 279px !important;
    transition: all .3s !important;
    margin-top: auto;
    opacity: 0.5;

    @media(max-width: $md3+px) {
        width: 100% !important;
        opacity: 1 !important;
        margin-top: 0 !important;
    }
}

:global(.aboutTeamSwiperSlideActive) {
    width: 488px !important;
    opacity: 1;

    @media(max-width: $md3+px) {
        width: 100% !important;
    }
}