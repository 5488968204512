@import '../../../styles/var';

.banner {
    z-index: 3;
    position: relative;
    margin-top: 200px;
    padding-bottom: 130px;

    @media(max-width: $md1+px) {
        margin-top: 140px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
        padding-bottom: 0;
    }

    @media(max-width: $md6+px) {
        margin-top: 90px;
    }

    .content {
        position: relative;
        z-index: 4;
        @media(max-width: $md4+px) {
            text-align: center;
        }

        .title {
            color: #FF314C;
            font-family: $mont;
            font-size: 100px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            display: flex;
            flex-direction: column;

            @media(max-width: $md4+px) {
                font-size: 40px;
                display: block;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }

            span {
                color: #FF314C;
                font-size: 45px;
                line-height: normal;

                @media(max-width: $md4+px) {
                    font-size: 40px;
                    margin-top: 0;
                }

                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
        }

        .subtitle {
            color: $white;
            font-family: $mont;
            font-size: 30px;
            font-weight: 800;
            line-height: normal;
            margin-top: 10px;
            max-width: 525px;

            @media(max-width: $md4+px) {
                margin: 15px auto 0;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 40px;
            max-width: 440px;

            @media(max-width: $md4+px) {
                margin: 30px auto 40px;
                max-width: 600px;
            }
        }

        .btn {
            @media(max-width: $md4+px) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        width: 591px;
        right: -120px;
        bottom: 0;

        @media(max-width: $md3+px) {
            width: 450px;
            right: -60px;
        }   

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 55px auto 0;
            right: unset;
            width: 418px;
        }

        @media(max-width: $md6+px) {
            width: 280px;
            margin-top: 40px;
        }

        img {
            width: 100%;
        }
    }
}