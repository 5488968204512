@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 200px;

    @media(max-width: $md1+px) {
        margin-top: 140px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    @media(max-width: $md6+px) {
        margin-top: 90px;
    }

    .content {
        position: relative;
        z-index: 4;

        .title {}

        .subtitle {
            margin: 40px 0 20px;
            max-width: 657px;

            @media(max-width: $md4+px) {
                margin: 20px 0;
            }

            @media(max-width: $md6+px) {
                margin: 12px 0 22px;
            }
        }

        .text {
            max-width: 525px;
        }

        .btn {
            margin-top: 120px;

            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        width: 839px;
        top: -70px;
        right: -200px;
        -webkit-text-stroke: 1.5px #fff;
        font-family: $mont;
        font-size: 100px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        color: transparent;

        @media(max-width: $md1+px) {
            width: 750px;
            top: -40px;
        }

        @media(max-width: $md3+px) {
            width: 650px;
            top: 0;
            right: -150px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            top: unset;
            right: unset;
            display: flex;
            margin: 60px auto 0;
            width: 100%;
            max-width: 650px;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        img {
            width: 100%;
        }
    }
}