@import "./_var.scss";
@import './mixins';

body,
html {
    background: #1E0204;
    color: $white;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #1E0204;
    }

    ::-webkit-scrollbar-thumb {
        background: #C62230;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #C62230;
    }
}

.page__not-found {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;

    font-size: 40px;
    font-family: $audio;

    @media (max-width: $md6+px) {
        font-size: 30px;
    }

    span {
        font-size: 80px;

        @media (max-width: $md6+px) {
            font-size: 54px;
        }
    }
}

.mt {
    margin-top: 200px;

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
