@import '../../../styles/var';

.forThose {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 525px;
    gap: 60px;

    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .title {
        @media(max-width: $md3+px) {
            max-width: 600px;
        }
        @media(max-width: $md4+px) {
            max-width: 550px;
        }
        @media(max-width: $md6+px) {
            max-width: 320px;
        }
    }
    
    .list {
        position: relative;
        z-index: 4;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media(max-width: $md6+px) {
            gap: 16px;
        }

        .item {
            display: flex;
            align-items: center;
            gap: 12px;

            .checkbox {
                width: 37px;

                @media(max-width: $md6+px) {
                    width: 31px;
                }
            }
        }
    }
}