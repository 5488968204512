@import '../../../styles/var';

.system {
    position: relative;
    display: grid;
    grid-template-columns: 490px 1fr;
    gap: 60px;

    @media(max-width: $md3+px) {
        grid-template-columns: 1fr auto;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 50px;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media(max-width: $md6+px) {
            gap: 16px;
        }

        .item {
            display: flex;
            align-items: center;
            gap: 12px;

            .checkbox {
                width: 37px;

                @media(max-width: $md6+px) {
                    width: 31px;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        @media(max-width: $md4+px) {
            display: block;
            text-align: center;
        }

        .title {
            color: #FF314C;
            font-family: $mont;
            font-size: 100px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            display: flex;
            flex-direction: column;

            @media(max-width: $md3+px) {
                font-size: 64px;
            }

            @media(max-width: $md4+px) {
                font-size: 30px;
                display: block;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }

            span {
                color: $white;
                font-size: 45px;
                line-height: normal;
                margin-left: 80px;

                @media(max-width: $md3+px) {
                    margin-left: 0;
                    font-size: 34px;
                }

                @media(max-width: $md4+px) {
                    font-size: 30px;
                    margin-top: 0;
                    color: #FF314C;
                }

                @media(max-width: $md6+px) {
                    font-size: 20px;
                }

                b {
                    font-size: 100px;

                    @media(max-width: $md3+px) {
                        font-size: 64px;
                    }

                    @media(max-width: $md4+px) {
                        font-size: 30px;
                        margin-top: 0;
                    }

                    @media(max-width: $md6+px) {
                        font-size: 20px;
                    }
                }
            }
        }

        .btn {
            display: flex;
            justify-content: flex-end;

            @media(max-width: $md4+px) {
                display: none;
            }
        }
    }
}