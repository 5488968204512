@import '../../../styles/var';

.whatYouLearn {
    position: relative;

    .title {
        max-width: 400px;
    }

    .list {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 15px 60px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }   
        
        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
        }
        
        li {
            margin-left: 20px;
            list-style-type: disc;
            list-style-position: outside;
        }
    }
}