@import '../../../styles/var';

.useful {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 525px;
    gap: 40px;

    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .content {
        .title {
            display: flex;
            flex-direction: column;
            gap: 5px;

            color: #FF314C;
            font-family: $mont;
            font-size: 100px;
            font-weight: 800;
            line-height: normal;

            @media(max-width: $md3+px) {
                display: block;
                font-size: 30px;
                text-align: center;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }

            span {
                color: $white;
                font-size: 80px;
                font-weight: 500;
                line-height: normal;

                @media(max-width: $md3+px) {
                    font-size: 30px;
                    color: #FF314C;
                    font-weight: 800;
                }

                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
        }

        .btn {
            margin-top: 64px;

            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }

    .tabs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .tab {
            background: #C62230;

            &.active {
                .tabText {
                    display: block;
                }

                .tabStatus::before {
                    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                }
            }

            &Header {
                cursor: pointer;
                padding: 20px;
                display: grid;
                grid-template-columns: 1fr 22px;
                gap: 40px;
                align-items: center;

                @media(max-width: $md6+px) {
                    grid-template-columns: 1fr 16px;
                    padding: 20px 15px;
                }

                .tabTitle {
                    b {
                        font-weight: 700;
                    }
                }

                .tabStatus {
                    position: relative;
                    width: 22px;
                    height: 22px;

                    @media(max-width: $md6+px) {
                        width: 16px;
                        height: 16px;
                    }

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background: $white;
                        transition: all .1s;
                    }

                    &::before {
                        width: 2px;
                        height: 100%;
                    }

                    &::after {
                        width: 100%;
                        height: 2px;
                    }
                }
            }

            &Text {
                display: none;
                padding: 0 60px 20px 20px;

                @media(max-width: $md6+px) {
                    padding: 0 15px 20px 15px;
                }
            }
        }
    }
}