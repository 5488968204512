@import '../../../styles/var';

.consultations {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 525px;
    gap: 60px;

    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .title {
        position: relative;
        z-index: 4;
        color: #FF314C;
        font-family: $mont;
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        display: flex;
        flex-direction: column;

        @media(max-width: $md4+px) {
            font-size: 40px;
            display: block;
        }

        @media(max-width: $md6+px) {
            font-size: 20px;
        }

        span {
            color: #fff;
            font-size: 45px;
            line-height: normal;

            @media(max-width: $md4+px) {
                font-size: 40px;
                margin-top: 0;
                color: #FF314C;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }
    }
    
    .list {
        position: relative;
        z-index: 4;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media(max-width: $md6+px) {
            gap: 16px;
        }

        .item {
            display: flex;
            align-items: center;
            gap: 12px;

            .checkbox {
                width: 37px;

                @media(max-width: $md6+px) {
                    width: 31px;
                }
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        bottom: -80px;
        left: 0;
        width: 500px;

        @media(max-width: $md3+px) {
            left: unset;
            right: 0;
            bottom: 20px;
            width: 400px;
        }

        @media(max-width: $md4+px) {
            display: none;
        }
    }
}