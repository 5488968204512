@import '../../../styles/var';

.wish {
    position: relative;

    @media(max-width: $md3+px) {
        display: grid;
        grid-template-columns: 400px 1fr;
        gap: 20px;
    }

    @media(max-width: $md4+px) {
        grid-template-columns: 320px 1fr;
    }

    @media(max-width: $md5+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .content {
        @media(max-width: $md3+px) {
            display: flex;
            flex-direction: column;
            gap: 23px;
        }

        .title {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            gap: 5px;

            @media(max-width: $md3+px) {
                position: relative;
            }

            span {
                &:nth-child(2) {
                    max-width: 350px;

                    @media(max-width: $md6+px) {
                        max-width: 280px;
                    }
                }
            }
        }

        .bottom {
            position: absolute;
            z-index: 5;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-end;

            @media(max-width: $md3+px) {
                position: relative;
            }

            span {
                &:nth-child(1) {
                    max-width: 270px;
                    text-align: right;

                    @media(max-width: $md6+px) {
                        max-width: 220px;
                    }
                }
            }
        }

        .btn {
            margin-top: 7px;
        }
    }

    .circles {
        position: relative;
        display: flex;
        margin: 0 auto;
        width: 715px;

        @media(max-width: $md3+px) {
            width: 100%;
        }

        .big {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .small {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 586px;
            height: 585px;

            @media(max-width: $md3+px) {
                height: auto;
                width: 83%;
            }

            img {
                width: 100%;
            }
        }

        .btn {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .item {
        position: absolute;
        z-index: 2;

        &_01 {
            width: 111px;
            left: 90px;
            bottom: 200px;

            @media (max-width: $md3+px) {
                left: unset;
                bottom: unset;
                right: 200px;
                top: -120px;
                width: 90px;
            }

            @media (max-width: $md5+px) {
                width: 70px;
                right: 100px;
                top: -80px;
            }

            @media (max-width: $md6+px) {
                width: 50px;
                right: 30px;
                top: -40px;
            }
        }

        &_02 {
            width: 87px;
            bottom: -100px;
            left: 210px;

            @media (max-width: $md3+px) {
                display: none;
            }
        }

        &_03 {
            width: 249px;
            top: 0;
            right: 0;

            @media (max-width: $md3+px) {
                top: unset;
                right: unset;
                bottom: -60px;
                left: 50px;
                width: 200px;
            }

            @media (max-width: $md4+px) {
                display: none;
            }
        }

        img {
            width: 100%;
        }
    }
}