@import '../../../styles/var';

.label {
    &.required {
        &::after {
            content: " *";
            color: red;
        }
    }
}

.inputField {
    position: relative;
}

.input {
    width: 100%;
    height: 46px;
    padding: 0 12px;
    border: 1px solid #D9D9D9;
    border-radius: 0;
    background: transparent;

    color: $white;
    font-family: $mont;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 1px;

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 0.5px;
    }

    &::placeholder {
        color: rgba($color: #fff, $alpha: 0.7);
    }

    &:focus {
        border-color: #fff;
    }

    &.error {
        border-color: red;
    }
}

.phone {
    padding: 0;
    border: none;
    text-align: left!important;

    &.containerClass {}

    .inputClass {
        width: 100% !important;
        height: 46px !important;
        padding: 0 12px;
        border: 1px solid #D9D9D9!important;
        border-radius: 0 !important;
        background: transparent !important;

        color: $white !important;
        font-family: $mont;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 150% !important;
        letter-spacing: 3px !important;

        @media(max-width: $md6+px) {
            font-size: 14px !important;
            letter-spacing: 2px !important;
        }

        &.error {
            border-color: red !important;
        }

        &:focus {
            border-color: #fff!important;
        }
    }

    .buttonClass {
        background: transparent !important;
        border: none !important;

        &:hover {
            background: transparent;
        }

        :global(.selected-flag) {
            transition: all .1s;
            border-radius: 0 0 0 0 !important;

            &:hover,
            &.open {
                background: transparent !important;
            }

            &:global(.open) {
                background: transparent !important;
            }
        }
    }

    .dropdownClass {
        color: #000 !important;
    }

    .searchClass {
        width: 100% !important;
        padding: 5px !important;

        input {
            border: none !important;
            width: 100%;
            margin-left: 0 !important;
        }
    }
}
