@import '../../../styles/var';

.weProvide {

    .title {
        text-align: center;

        @media(max-width: $md6+px) {
            max-width: 280px;
            margin: 0 auto;
        }
    }

    .content {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;

        @media(max-width: $md4+px) {
            margin-top: 50px;
            gap: 40px;
        }

        @media(max-width: 770px) {
            display: block;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media(max-width: $md6+px) {
                gap: 16px;
            }

            .item {
                display: flex;
                align-items: center;
                gap: 12px;

                .checkbox {
                    width: 37px;

                    @media(max-width: $md6+px) {
                        width: 31px;
                    }
                }
            }
        }

        .image {
            width: 380px;

            @media(max-width: $md4+px) {
                width: 310px;
            }
        }
    }
}