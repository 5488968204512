@import '../../../styles/var';

.wrap {
    background: #C62230;
}

.banner {
    position: relative;
    height: 100vh;
    padding-top: 70px;
    min-height: 850px;
    max-height: 1000px;

    @media(max-width: $md1+px) {
        padding-top: 0px;
        min-height: 710px;
        max-height: 850px;
    }

    @media(max-width: $md3+px) {
        height: auto;
        min-height: unset;
        max-height: unset;
        padding-bottom: 50px;
        padding-top: 40px;
    }

    @media(max-width: $md6+px) {
        padding-bottom: 0;
    }

    .content {
        position: relative;
        z-index: 5;
        width: 700px;

        @media(max-width: $md3+px) {
            margin: 0 auto;
            width: 100%;
        }

        .head {
            position: relative;
            display: inline-block;

            @media(max-width: $md3+px) {
                width: 700px;
                margin: 0 auto;
                display: block;
            }

            @media(max-width: $md4+px) {
                width: 553px;
            }

            @media(max-width: 640px) {
                width: 425px;
            }

            @media(max-width: $md6+px) {
                width: 338px;
            }

            .title {
                display: flex;

                @media(max-width: $md3+px) {
                    text-align: center;
                }

                span {
                    vertical-align: text-top;

                    &:nth-child(2) {
                        display: inline-block;
                        margin-top: 1.7rem;

                        @media(max-width: $md4+px) {
                            margin-top: 1.4rem;
                        }

                        @media(max-width: 640px) {
                            margin-top: 1.1rem;
                        }

                        @media(max-width: $md6+px) {
                            margin-top: 0.8rem;
                        }
                    }
                }
            }

            .subtitle {
                position: absolute;
                right: 10px;
                top: 200px;
                text-align: right;
                display: flex;
                flex-direction: column;
                gap: 7px;

                @media(max-width: $md4+px) {
                    top: 160px;
                }

                @media(max-width: 640px) {
                    top: 130px;
                    right: 6px;
                }

                @media(max-width: $md6+px) {
                    top: 110px;
                }
            }
        }

        .text_01 {
            max-width: 240px;
            margin-top: -40px;

            @media(max-width: $md1+px) {
                margin-top: -70px;
            }

            @media(max-width: $md3+px) {
                margin-left: auto;
                text-align: right;
            }
        }

        .text_02 {
            max-width: 300px;
            text-align: right;
            margin: 25px 0 0 auto;

            @media(max-width: $md1+px) {
                margin-top: 10px;
            }

            @media(max-width: $md3+px) {
                margin: 80px 0 0 0;
                text-align: left;
            }

            @media(max-width: $md4+px) {
                max-width: 223px;
            }
        }

        .btn {
            margin-top: 35px;

            @media(max-width: $md1+px) {
                margin-top: 20px;
            }

            @media(max-width: $md3+px) {
                margin-top: 410px;
            }

            @media(max-width: $md4+px) {
                margin-top: 310px;
            }

            @media(max-width: $md6+px) {
                margin-top: -10px;
                justify-content: center;
                display: flex;
            }
        }
    }

    .headImg {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: -230px;
        width: 696px;

        @media(max-width: $md1+px) {
            width: 550px;
            right: -110px;
        }

        @media(max-width: $md3+px) {
            right: 0;
            width: 530px;
        }

        @media(max-width: $md4+px) {
            width: 470px;
            right: unset;
            left: 300px;
        }

        @media(max-width: 800px) {
            left: 230px;
        }

        @media(max-width: $md6+px) {
            width: 316px;
            position: relative;
            margin: 0 auto;
            display: flex;
            left: 0;
        }
    }
}