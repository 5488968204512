@import '../../../styles/var';

.whyChoose {
    position: relative;

    .title {
        position: relative;
        z-index: 4;

        .sub_01 {

            span {
                &:nth-child(2) {
                }
            }
        }

        .sub_02 {
            display: block;
        }
    }

    .list {
        position: relative;
        z-index: 4;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px 50px;
        margin-top: 50px;

        @media(max-width: $md4+px) {
            grid-template-columns: auto 1fr;
        }

        @media(max-width: 900px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 40px;
        }

        @media(max-width: $md6+px) {
            margin-top: 30px;
        }

        .item {
            list-style-type: disc;
            list-style-position: outside;
            margin-left: 25px;

            @media(max-width: $md4+px) {
                margin-left: 22px;
            }

            @media(max-width: $md6+px) {
                margin-left: 16px;
                max-width: 260px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        top: -150px;
        right: -160px;
        width: 495px;

        @media(max-width: $md3+px) {
            width: 400px;
            right: -60px;
            top: -120px;
        }

        @media(max-width: $md4+px) {
            width: 320px;
            right: -20px;
        }

        @media(max-width: 900px) {
            top: 50px;
            right: -100px;
        }

        @media(max-width: $md5+px) {
            top: 80px;
            right: -150px;
        }

        @media(max-width: $md6+px) {
            width: 280px;
            right: -190px;
            top: unset;
            bottom: -50px;
        }
    }
}