@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 200px;

    @media(max-width: $md1+px) {
        margin-top: 140px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    @media(max-width: $md6+px) {
        margin-top: 90px;
    }

    .title {
        color: #FF314C;
        font-family: $mont;
        font-size: 100px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        display: flex;
        flex-direction: column;

        @media(max-width: $md4+px) {
            font-size: 40px;
            display: block;
        }

        @media(max-width: $md6+px) {
            font-size: 20px;
        }

        span {
            color: #FF314C;
            font-size: 45px;
            line-height: normal;

            @media(max-width: $md4+px) {
                font-size: 40px;
                margin-top: 0;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        top: 40px;
        right: -150px;
        width: 766px;

        @media (max-width: $md3+px) {
            width: 650px;
            top: 100px;
            right: -170px;
        }

        @media (max-width: $md4+px) {
            position: relative;
            top: unset;
            right: unset;
            display: flex;
            margin: 25px auto;
            max-width: 441px;
            width: 100%;
        }

        @media (max-width: $md6+px) {
            margin: 15px auto;
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 525px;
        margin: 30px 0 50px;

        @media (max-width: $md4+px) {
            max-width: 100%;
            margin: 0 0 40px;
        }
    }
    
    .title, .text, .btn {
        position: relative;
        z-index: 4;
    }
}