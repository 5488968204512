@import '../../../styles/var';

.quote {
    position: relative;

    .text {
        position: relative;
        max-width: 843px;
        margin: 0 auto;
        text-align: center;

        @media(max-width: $md4+px) {
            max-width: 90%;
        }

        @media(max-width: 600px) {
            max-width: 85%;
        }

        @media(max-width: $md6+px) {
            width: calc(100% - 56px);
        }

        &::before, &::after {
            content: '';
            position: absolute;
            width: 32px;
            height: 32px;
            background-image: url('../../../assets/img/HomePage/Quote/quote.svg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;

            @media(max-width: $md6+px) {
                width: 25px;
                height: 25px;
            }
        }

        &::before {
            left: -45px;
            top: -5px;
            transform: scaleX(-1);

            @media(max-width: $md6+px) {
                left: -30px;
            }
        }

        &::after {
            right: -45px;
            bottom: -5px;
            
            @media(max-width: $md6+px) {
                right: -30px;
            }
        }
    }

    .item {
        position: absolute;

        &_01 {
            bottom: -115px;
            left: 45px;
            width: 127px;

            @media(max-width: $md3+px) {
                width: 100px;
            }   

            @media(max-width: $md6+px) {
                width: 80px;
                left: -10px;
                bottom: -70px;
            }
        }
        
        &_02 {
            right: 80px;
            top: -140px;
            width: 67px;

            @media(max-width: $md3+px) {
                width: 65px;
                top: -110px;
                right: 50px;
            }

            @media(max-width: $md6+px) {
                width: 40px;
                top: -70px;
            }
        }

        img {
            width: 100%;
        }
    }
}