@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 200px;
    padding-bottom: 80px;

    @media(max-width: $md1+px) {
        margin-top: 140px;
    }

    @media(max-width: $md3+px) {
        padding-bottom: 50px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
        padding-bottom: 0;
    }

    @media(max-width: $md6+px) {
        margin-top: 90px;
    }

    .content {
        position: relative;
        z-index: 4;

        @media(max-width: $md4+px) {
            text-align: center;
        }

        .title {
            color: #FF314C;
            font-family: $mont;
            font-size: 100px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            display: flex;
            flex-direction: column;
    
            @media(max-width: $md4+px) {
                font-size: 40px;
                display: block;
            }
    
            @media(max-width: $md6+px) {
                font-size: 20px;
            }
    
            span {
                color: #FF314C;
                font-size: 45px;
                line-height: normal;
    
                @media(max-width: $md4+px) {
                    font-size: 40px;
                    margin-top: 0;
                }
    
                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
        }
    
        .text {
            margin: 30px 0 50px;
            max-width: 500px;

            @media(max-width: $md4+px) {
                margin: 40px auto;
                max-width: 525px;
            }
            
            @media(max-width: $md6+px) {
                margin: 30px auto;
            }
        }
        
        .btn {
            @media(max-width: $md4+px) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        right: -300px;
        top: -260px;
        width: 1135px;

        @media(max-width: $md1+px) {
            width: 1000px;
            top: -200px;
            right: -200px;
        }

        @media(max-width: $md3+px) {
            width: 800px;
            top: -120px;
            right: -160px;
        }

        @media(max-width: $md4+px) {
            max-width: 614px;
            width: 100%;
            position: relative;
            right: unset;
            top: unset;
            display: flex;
            margin: 0 auto 0;
        }

        @media(max-width: $md6+px) {
            width: calc(100% + 80px);
            transform: translateX(-36px);
        }

        img {
            width: 100%;
        }
    }
}