@import '../../../styles/var';

.formWrap {
    position: relative;

    .status {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        text-align: center;
        left: 0;
        top: 0;
        transform: scale(0.7);
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 20px 20px;

        &Error,
        &Success {
            z-index: 3;
        }

        &Process {
            z-index: 2;
        }

        &Icon {
            width: 50px;
            height: 50px;
        }

        &Text {
            color: #fff;
            margin-top: 15px;
            letter-spacing: 0.5px;
        }


        &.active {
            transform: scale(1);
            opacity: 1;
            pointer-events: visible;

        }
    }

    .form {
        position: relative;
        z-index: 1;

        &.blur {
            filter: blur(5px);
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .submit {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .check {
        display: grid;
        grid-template-columns: 16px 1fr;
        align-items: center;
        gap: 15px;
        &.incorrect {
            .checkText {
                color: red!important;
            }
        }
        &Box {
            position: relative;
            border: 1px solid #D9D9D9;
            width: 16px;
            height: 16px;
            cursor: pointer;
            transition: all .1s linear;

            &Active {
                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 10px;
                    height: 10px;
                    background: #D9D9D9;
                }
            }
        }
        &Text {
            color: $white;
            text-align: left;
            line-height: 120%;
            font-size: 14px;

            a {
                font-weight: 400;
                color: $white;
                text-decoration: underline;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}