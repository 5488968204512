@import '../../../styles/var';

.ourMission {

    .title {

    }

    .text {
        margin: 15px 0 40px;
        max-width: 1150px;

        @media(max-width: $md6+px) {
            margin-bottom: 30px;
        }
    }

    .row {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 60px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        @media(max-width: $md6+px) {
            gap: 15px;
        }
    }
}