@import '../../../styles/var';

.wrap {
    position: relative;
    height: 1000px;

    @media(max-width: $md3+px) {
        height: 950px;
    }

    @media(max-width: $md4+px) {
        height: 620px;
    }

    @media(max-width: 650px) {
        height: 630px;
    }

    @media(max-width: $md6+px) {
        height: 506px;
    }

    .title {
        position: relative;
        z-index: 3;
    }

    .circleWrap {
        position: absolute;
        z-index: 4;

        &_01 {
            right: 0;
            top: 0;

            @media(max-width: 730px) {
                top: 160px;
            }

            @media(max-width: 650px) {}

            @media(max-width: $md6+px) {
                top: 0;
            }

            .circle {
                width: 506px;
                height: 506px;

                @media(max-width: $md3+px) {
                    width: 400px;
                    height: 400px;
                }

                @media(max-width: $md4+px) {
                    width: 280px;
                    height: 280px;
                }

                @media(max-width: 730px) {
                    width: 230px;
                    height: 230px;
                }

                @media(max-width: 650px) {}

                @media(max-width: $md6+px) {
                    width: 160px;
                    height: 160px;
                }

                &::before {
                    background: #D9D9D9;
                }

                &:hover {
                    border-color: #D9D9D9;

                    * {
                        color: #000;
                    }
                }
            }
        }

        &_02 {
            top: 320px;
            left: 336px;

            @media(max-width: $md3+px) {
                top: 350px;
                left: 310px;
            }

            @media(max-width: $md4+px) {
                top: 240px;
                left: 200px;
            }

            @media(max-width: 730px) {
                top: 200px;
                left: 100px;
            }

            @media(max-width: 650px) {
                left: 20px;
            }

            @media(max-width: $md6+px) {
                top: 100px;
            }

            .circle {
                width: 382px;
                height: 382px;

                @media(max-width: $md3+px) {
                    width: 330px;
                    height: 330px;
                }

                @media(max-width: $md4+px) {
                    width: 215px;
                    height: 215px;
                }

                @media(max-width: 730px) {
                    width: 190px;
                    height: 190px;
                }

                @media(max-width: 650px) {
                    width: 180px;
                    height: 180px;
                }

                @media(max-width: $md6+px) {}

                &::before {
                    background: $red;
                }

                &:hover {
                    border-color: $red;
                }
            }
        }

        &_03 {
            bottom: 40px;
            left: 0;

            @media(max-width: $md4+px) {
                bottom: 0;
            }

            @media(max-width: 730px) {}

            @media(max-width: 650px) {}

            @media(max-width: $md6+px) {}

            .circle {
                width: 372px;
                height: 372px;

                @media(max-width: $md3+px) {
                    width: 340px;
                    height: 340px;
                }

                @media(max-width: $md4+px) {
                    width: 210px;
                    height: 210px;
                }

                @media(max-width: 730px) {}

                @media(max-width: 650px) {}

                @media(max-width: $md6+px) {
                    width: 180px;
                    height: 180px;
                }

                &::before {
                    background: $red;
                }

                &:hover {
                    border-color: $red;
                }
            }
        }

        &_04 {
            bottom: 0;
            right: 191px;

            @media(max-width: $md3+px) {
                right: 0px;
            }

            @media(max-width: 730px) {}

            @media(max-width: 650px) {}

            @media(max-width: $md6+px) {
                bottom: 99px;
            }

            .circle {
                width: 372px;
                height: 372px;

                @media(max-width: $md3+px) {
                    width: 340px;
                    height: 340px;
                }

                @media(max-width: $md4+px) {
                    width: 210px;
                    height: 210px;
                }

                @media(max-width: 730px) {}

                @media(max-width: 650px) {}

                @media(max-width: $md6+px) {
                    width: 180px;
                    height: 180px;
                }

                &::before {
                    background: $red;
                }

                &:hover {
                    border-color: $red;
                }
            }
        }

        .circle {
            position: relative;
            border-radius: 506px;
            border: 1px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all .3s;
            padding: 10px;

            &Content {
                position: relative;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 30px;

                @media(max-width: $md4+px) {
                    gap: 16px;
                }

                @media(max-width: $md6+px) {
                    gap: 12px;
                }

                * {
                    transition: all .3s;
                }

                .icon {
                    width: 60px;

                    @media(max-width: $md4+px) {
                        width: 40px;
                    }

                    @media(max-width: $md6+px) {
                        width: 30px;
                    }
                }
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                left: 50%;
                top: 50%;
                width: 0;
                height: 0;
                transform: translateX(-50%) translateY(-50%);
                transition: all .3s;
                border-radius: 100%;
            }

            &:hover {
                &::before {
                    width: calc(100% + 1px);
                    height: calc(100% + 1px);
                }
            }
        }
    }

}