@import '../../../styles/var';

.result {
    position: relative;

    .title {
        color: #FF314C;
        font-family: $mont;
        font-size: 100px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        display: flex;
        flex-direction: column;

        @media(max-width: $md4+px) {
            font-size: 30px;
            display: block;
            text-align: center;
        }

        @media(max-width: $md6+px) {
            font-size: 20px;
        }

        span {
            font-size: 40px;
            line-height: normal;

            @media(max-width: $md4+px) {
                font-size: 30px;
                margin-top: 0;
            }

            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 30px;
        margin-top: 40px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
        }
    
        @media(max-width: $md6+px) {
            gap: 16px;
            margin-top: 40px;
        }

        .item {
            display: flex;
            align-items: center;
            gap: 12px;

            .checkbox {
                width: 37px;

                @media(max-width: $md6+px) {
                    width: 31px;
                }
            }
        }
    }
}