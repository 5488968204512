@import '../../../styles/var';

.questions {
    position: relative;

    .title {
        max-width: 600px;

        @media (max-width: $md4+px) {
            max-width: 400px;
        }
    }

    .list {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        gap: 15px 60px;
        justify-content: space-between;
        margin-top: 30px;

        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
                list-style-type: disc;
                list-style-position: outside;
                margin-left: 25px;

                @media (max-width: $md6+px) {
                    margin-left: 20px;
                }
            }
        }
    }
}