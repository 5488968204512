@import "../../../styles/var";

.wrap {
    background: #C62230;
}

.footer {
    padding: 30px 0 100px;
    color: #D9D9D9;

    @media(max-width: $md4+px) {
        padding-bottom: 60px;
    }

    @media(max-width: $md6+px) {
        padding: 20px 0 40px;
    }

    .logo {
        width: 130px;

        @media(max-width: $md6+px) {
            width: 110px;
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-top: 35px;

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        @media(max-width: $md6+px) {
            gap: 20px;
            margin-top: 20px;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 14px;
            justify-content: flex-end;

            @media(max-width: $md6+px) {
                gap: 10px;
            }

            .rights {
                text-align: right;

                @media(max-width: $md4+px) {
                    text-align: left;
                }
            }
        }

        a {
            color: inherit;
            color: #D9D9D9;

            &:hover {
                color: #fff;
            }
        }
    }
}