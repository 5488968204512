@import '../../../styles/var';

.joinUs {
    position: relative;

    .title {
        position: relative;
        z-index: 4;
        text-align: center;

        span {
            color: $white;
        }
    }

    .text {
        position: relative;
        z-index: 4;
        text-align: center;
        margin: 15px auto 50px;

        @media(max-width: $md6+px) {
            margin-bottom: 30px;
        }
    }

    .form {
        position: relative;
        z-index: 4;
        max-width: 450px;
        margin: 0 auto;
    }

    .item {
        position: absolute;
        z-index: 3;

        &_01 {
            width: 127px;
            left: 60px;
            top: 140px;

            @media(max-width: $md3+px) {
                width: 100px;
                left: 0;
            }
        }

        &_02 {
            width: 83px;
            left: 30px;
            bottom: 50px;
        }

        &_03 {
            width: 79px;
            left: 210px;
            bottom: -70px;
        }

        img {
            width: 100%;
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: -340px;
        bottom: -200px;
        width: 925px;
        
        @media(max-width: $md3+px) {
            width: 700px;
        }

        @media (max-width: $md4+px) {
            bottom: -140px;
            width: 600px;
            right: -250px;
        }
    
        @media (max-width: $md6+px) {
            bottom: -100px;
            right: -200px;
            width: 400px;
        }
    }
}