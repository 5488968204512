@import '../../../styles/var';

.wrap {
    position: relative;
    height: 900px;

    @media(max-width: $md4+px) {
        height: 710px;
    }

    @media(max-width: 650px) {
        height: 600px;
    }

    @media(max-width: $md6+px) {
        height: 506px;
    }

    .content {
        position: relative;
        z-index: 10;
        width: 490px;
        margin-left: auto;

        @media(max-width: $md4+px) {
            width: 290px;
        }

        @media(max-width: $md6+px) {
            width: 248px;
        }

        .title {
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            span {
                display: flex;
            }

            .ttl_01 {
                padding-right: 5px;

                @media(max-width: $md4+px) {
                    padding-right: 3px;
                }
            }

            .ttl_02 {
                margin-top: -35px;

                @media(max-width: $md4+px) {
                    margin-top: -18px;
                }

                @media(max-width: $md6+px) {
                    margin-top: -15px;
                }
            }
        }

        .subtitle {}
    }

    .circleWrap {
        position: absolute;
        z-index: 4;

        &_01 {
            left: 0;
            top: -60px;

            @media(max-width: $md3+px) {
                top: 110px;
            }

            @media(max-width: $md4+px) {
                top: 0;
            }

            @media(max-width: 730px) {
                top: 130px;
            }

            @media(max-width: 650px) {
            
            }

            @media(max-width: $md6+px) {
                top: 120px;
            }

            .circle {
                width: 506px;
                height: 506px;

                @media(max-width: $md4+px) {
                    width: 353px;
                    height: 353px;
                }

                @media(max-width: 730px) {
                    width: 330px;
                    height: 330px;
                }

                @media(max-width: 650px) {
                    width: 260px;
                    height: 260px;
                }
    
                @media(max-width: $md6+px) {
                    width: 176px;
                    height: 176px;
                }

                &::before {
                    background: #D9D9D9;
                }

                &:hover {
                    border-color: #D9D9D9;

                    * {
                        color: #000;
                    }
                }
            }
        }

        &_02 {
            top: 220px;
            right: 270px;

            @media(max-width: $md3+px) {
                right: 0;
                top: 190px;
            }

            @media(max-width: $md4+px) {
                top: 155px;
            }

            @media(max-width: 730px) {
                top: 260px;
            }

            @media(max-width: 650px) {
                
            }

            @media(max-width: $md6+px) {
                top: 230px;
            }

            .circle {
                width: 382px;
                height: 382px;

                @media(max-width: $md4+px) {
                    width: 239px;
                    height: 239px;
                }

                @media(max-width: 650px) {
                    width: 190px;
                    height: 190px;
                }
    
                @media(max-width: $md6+px) {
                    width: 154px;
                    height: 154px;
                }

                &::before {
                    background: $red;
                }

                &:hover {
                    border-color: $red;
                }
            }
        }

        &_03 {
            bottom: 0;
            left: 144px;

            @media(max-width: $md3+px) {
                left: 350px;
            }

            @media(max-width: $md4+px) {
                left: 146px;
            }

            @media(max-width: 730px) {
                left: 170px;
            }

            @media(max-width: 650px) {
                left: 120px;
            }

            @media(max-width: $md6+px) {
                left: 45px;
            }

            .circle {
                width: 372px;
                height: 372px;

                @media(max-width: $md4+px) {
                    width: 325px;
                    height: 325px;
                }

                @media(max-width: 730px) {
                    width: 250px;
                    height: 250px;
                }

                @media(max-width: 650px) {
                    width: 190px;
                    height: 190px;
                }
    
                @media(max-width: $md6+px) {
                    width: 154px;
                    height: 154px;
                }

                &::before {
                    background: $red;
                }

                &:hover {
                    border-color: $red;
                }
            }
        }

        .circle {
            position: relative;
            border-radius: 506px;
            border: 1px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all .3s;
            padding: 10px;

            &Content {
                position: relative;
                z-index: 2;
                display: flex;
                flex-direction: column;
                gap: 5px;

                * {
                    transition: all .3s;
                }
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                left: 50%;
                top: 50%;
                width: 0;
                height: 0;
                transform: translateX(-50%) translateY(-50%);
                transition: all .3s;
                border-radius: 100%;
            }

            &:hover {
                &::before {
                    width: calc(100% + 1px);
                    height: calc(100% + 1px);
                }
            }
        }
    }

    .item {
        position: absolute;
        z-index: 3;

        &_01 {
            left: -20px;
            bottom: 310px;
            width: 87px;

            @media(max-width: $md3+px) {
                bottom: 160px;
                left: 40px;
            }

            @media(max-width: $md4+px) {
                width: 70px;
                left: 20px;
            }

            @media(max-width: 650px) {
                display: none;
            }
        }

        &_02 {
            width: 138px;
            bottom: 85px;
            left: 540px;

            @media(max-width: $md3+px) {
                left: unset;
                right: 60px;
            }
            
            @media(max-width: $md4+px) {
                width: 110px;
            }

            @media(max-width: 730px) {
                bottom: unset;
                top: 130px;
                width: 90px;
            }

            @media(max-width: $md6+px) {
                width: 60px;
                right: 30px;
                top: 120px;
            }
        }

        &_03 {
            width: 475px;
            right: 0;
            bottom: -100px;

            @media(max-width: $md3+px) {
                display: none;
            }
        }

        &_04 {
            width: 69px;
            bottom: -140px;
            right: 150px;
        }

        &_05 {
            width: 127px;
            top: 230px;
            right: 30px;

            @media(max-width: $md3+px) {
                display: none;
            }

            @media(max-width: 730px) {
                display: flex;
                top: unset;
                bottom: 50px;
                right: 50px;
                width: 80px;
            }

            @media(max-width: 650px) {
                bottom: 30px;
                width: 70px;
            }

            @media(max-width: $md6+px) {
                width: 50px;
            }
        }

        img {
            width: 100%;
        }
    }
}