@import '../../../styles/var';

.container {
    @media(max-width: $md6+px) {
        padding: 0 !important;
    }
}

.advantage {
    position: relative;
    background-image: url('../../../assets/img/HomePage/WhatToKnow/bg.avif');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 40px;

    @media(max-width: $md6+px) {
        padding: 40px 18px;
    }

    .title {
        color: #C62432;
    }

    .subtitle {
        max-width: 700px;
        margin: 10px auto 50px;

        @media(max-width: $md4+px) {
            margin-top: 5px;
            max-width: 550px;
        }

        @media(max-width: $md6+px) {
            margin: 8px auto 15px;
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
    }

    .item {
        position: absolute;

        @media(max-width: $md5+px) {
            display: none;
        }

        &_01 {
            width: 161px;
            left: 40px;
            bottom: 10px;

            @media(max-width: $md3+px) {
                width: 140px;
                bottom: 5px;
                left: 20px;
            }
        }

        &_02 {
            width: 74px;
            top: 55px;
            right: 105px;

            @media(max-width: $md3+px) {
                width: 50px;
                right: 120px;
                bottom: 95px;
                top: unset;
            }
        }

        img {
            width: 100%;
        }
    }
}