@import '../../../styles/var';

.wrap {
    background: #311719;
}

.achievements {
    position: relative;
    overflow: hidden;
    padding: 20px 0;
    display: grid;
    grid-template-columns: 525px 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 60px;

    @media(max-width: $md3+px) {
        grid-template-columns: 500px 1fr;
        padding: 40px 0;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        padding: 80px 0;
    }

    @media(max-width: $md6+px) {
        padding: 60px 0;
        gap: 40px;
    }

    .content {
        position: relative;
        z-index: 4;
        display: flex;
        flex-direction: column;
        gap: 40px;
        max-width: 525px;
        @media(max-width: $md4+px) {
            max-width: 100%;
        }
        .block {
            display: flex;
            flex-direction: column;
            gap: 8px;

            @media(max-width: $md6+px) {
                gap: 12px;
            }
        }
    }

    .image {
        max-width: 612px;
        width: 100%;

        @media(max-width: $md3+px) {
        
        }
    }
}