@import '../../../styles/var';

.weOffer {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 80px;

    @media(max-width: $md3+px) {
        gap: 40px;
    }

    @media(max-width: 900px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .content {
        .title {
            display: flex;
            flex-direction: column;
        }

        .text {
            margin: 30px 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            max-width: 450px;

            @media(max-width: $md4+px) {
                max-width: 390px;
            }
        }

        .btn {

        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @media(max-width: $md4+px) {
            gap: 15px;
        }

        .card {
            position: relative;
            overflow: hidden;
            height: 150px;
            padding: 10px 35px;
            display: grid;
            grid-template-columns: 70px 1fr;
            gap: 30px;
            align-items: center;
            justify-content: space-between;

            @media(max-width: $md4+px) {
                grid-template-columns: 65px 1fr;
                height: 125px;
            }   

            @media(max-width: $md6+px) {
                grid-template-columns: 35px 1fr;
                height: 85px;
                padding: 15px;
            }

            &:hover {
                .cardImage {
                    transform: scale(1.1);
                }

                .cardBtn {
                    background: $red;
                    border-color: $red;
                }
            }

            &Image {
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all .2s;
            }

            &Btn {
                position: relative;
                z-index: 3;
                width: 70px;
                height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $white;
                border-radius: 100%;
                transition: all .2s;
                cursor: pointer;

                @media(max-width: $md4+px) {
                    width: 65px;
                    height: 65px;
                }

                @media(max-width: $md6+px) {
                    width: 35px;
                    height: 35px;
                    background: $red;
                    border-color: $red;
                }

                &:hover {
                    opacity: 0.7;
                }

                .arrow {
                    width: 40px;
                    height: 40px;

                    @media(max-width: $md4+px) {
                        width: 36px;
                        height: 36px;
                    }

                    @media(max-width: $md6+px) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            &Title {
                position: relative;
                z-index: 3;
                text-align: right;
            }
        }
    }
}