@import '../../../styles/var';

.wrap {
    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
    .titleWrap {
        @media(max-width: $md3+px) {
            padding: 0 40px;
        }
        @media(max-width: 600px) {
            padding: 0 18px;
        }
        .title {
            color: $red;
            text-align: center;
            width: 100%;
            max-width: 1519px;
            margin: 0 auto;
            display: flex;
            margin-top: 6px;

            @media(max-width: $md3+px) {
                width: 102%;
                transform: translateX(-1%);
            }

            @media(max-width: $md6+px) {
                margin-top: 0;
            }
        }
    }

    .text_01 {
        margin: 50px 0 30px auto;
        max-width: 240px;
        text-align: right;
    }

    .text_02 {
        max-width: 220px;
    }

    .btn {
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
}