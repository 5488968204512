@import '../../../styles/var';

.whyChoose {
    position: relative;

    .title {
        color: $white;
        font-family: $mont;
        font-size: 45px;
        font-weight: 800;
        line-height: normal;
        display: flex;
        flex-direction: column;

        @media(max-width: $md4+px) {
            font-size: 40px;
        }

        @media(max-width: $md6+px) {
            font-size: 20px;
        }

        span {
            color: #FF314C;
            font-family: $audio;
            font-size: 150px;
            line-height: 110%;
            margin-top: 10px;

            @media(max-width: $md4+px) {
                font-size: 100px;
            }

            @media(max-width: $md6+px) {
                font-size: 60px;
                margin-top: 5px;
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        gap: 60px;
        margin-top: 40px;

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
                list-style-type: disc;
                list-style-position: outside;
                margin-left: 25px;

                @media(max-width: $md6+px) {
                    margin-left: 20px;
                }
            }
        }
    }
}