@import './var';

.font-banner-h1 {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-360 {
    color: $white;
    font-family: $audio;
    font-size: 360px;
    font-weight: 400;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 285px;
    }

    @media(max-width: 640px) {
        font-size: 220px;
    }

    @media(max-width: $md6+px) {
        font-size: 170px;
    }
}

.font-165 {
    color: $white;
    font-family: $audio;
    font-size: 165px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 130px;
    }

    @media(max-width: 640px) {
        font-size: 100px;
    }

    @media(max-width: $md6+px) {
        font-size: 82px;
    }
}

.font-162 {
    color: $white;
    font-family: $audio;
    font-size: 162.024px;
    font-weight: 400;
    line-height: 100%;

    @media(max-width: $md4+px) {
        font-size: 88px;
    }

    @media(max-width: $md6+px) {
        font-size: 75px;
    }
}

.font-150 {
    color: $white;
    font-family: $audio;
    font-size: 150px;
    font-weight: 400;
    line-height: 85%;

    @media(max-width: $md4+px) {
        font-size: 88px;
    }

    @media(max-width: $md6+px) {
        font-size: 75px;
    }
}

.font-100 {
    color: #FF314C;
    font-family: $mont;
    font-size: 100px;
    font-weight: 800;
    line-height: 110%;

    @media(max-width: $md3+px) {
        font-size: 70px;
    }

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-100-84-64-30 {
    color: #FF314C;
    font-family: $mont;
    font-size: 100px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md1+px) {
        font-size: 84px;
    }

    @media(max-width: $md4+px) {
        font-size: 64px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-80 {
    color: $white;
    font-family: $audio;
    font-size: 80px;
    font-weight: 400;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 56px;
    }

    @media(max-width: 650px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 23px;
    }
}

.font-80-25-20 {
    color: $white;
    font-family: $mont;
    font-size: 80px;
    font-weight: 500;
    line-height: 70%;

    @media(max-width: $md3+px) {
        font-size: 50px;
    }

    @media(max-width: $md4+px) {
        font-size: 25px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-45-30-20 {
    color: $white;
    font-family: $mont;
    font-size: 45px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 30px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-43 {
    color: $white;
    font-family: $mont;
    font-size: 43px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: 640px) {
        font-size: 24px;
    }

    @media(max-width: $md6+px) {
        font-size: 22px;
    }
}

.font-40 {
    color: $white;
    font-family: $mont;
    font-size: 40px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 25px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-40-800 {
    color: #FF314C;
    font-family: $mont;
    font-size: 40px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 25px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-40-30-20 {
    color: #FF314C;
    font-family: $mont;
    font-size: 40px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 30px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-30-26-20-16 {
    color: $white;
    font-family: $mont;
    font-size: 30px;
    font-weight: 800;
    line-height: 140%;

    @media(max-width: $md1+px) {
        font-size: 26px;
    }

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-30-20 {
    color: #FF314C;
    font-family: $mont;
    font-size: 30px;
    font-weight: 800;
    line-height: 140%;

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-30 {
    color: $white;
    font-family: $mont;
    font-size: 30px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-27 {
    color: $white;
    font-family: $mont;
    font-size: 27px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: 640px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-27-25-20 {
    color: $white;
    font-family: $mont;
    font-size: 27px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 25px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-27-25-16 {
    color: #1E0204;
    font-family: $mont;
    font-size: 27px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-25 {
    color: $white;
    font-family: $mont;
    font-size: 25px;
    font-weight: 800;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 15px;
    }
}

.font-25-16 {
    color: $white;
    font-family: $mont;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-23 {
    color: $white;
    font-family: $mont;
    font-size: 23px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 14px;
    }

    @media(max-width: $md6+px) {
        font-size: 12px;
    }
}

.font-23-16-14 {
    color: $white;
    font-family: $mont;
    font-size: 23px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-18 {
    color: $white;
    font-family: $mont;
    font-size: 18px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-17 {
    color: $white;
    font-family: $mont;
    font-size: 17px;
    font-weight: 500;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    span {
        color: #FF314C;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}