@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba($color: #C62230, $alpha: 1);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all .3s;

    @media(max-width: $md3+px) {
        height: 72px;
    }

    @media(max-width: $md6+px) {
        height: 65px;
    }

    &.hideBg {
        background: rgba($color: #C62230, $alpha: 0);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    &::before {
        content: '';
        background: #C62230;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        z-index: -1;
        transition: all .3s;
    }

    &Active {
        &::before {
            opacity: 1;
            height: 100vh;
        }
    }

    .mobileMenu {
        @media(min-width: 1221px) {
            display: none;
        }

        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        .aLink {
            position: relative;
            z-index: 10;
            display: block;
            text-align: center;
            color: $white;
            font-family: $mont;
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 20px;

            @media (max-width: $md6+px) {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }

        &Active {
            display: flex;
            transition: transform .5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }

        .mobileBtn {
            display: flex;
            justify-content: center;
            margin-top: 50px;
        }
    }

    .headerRow {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 75px;
        align-items: center;
        padding: 12px 0;
        height: 100%;

        @media(max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
            gap: 40px;
        }

        &Logo {
            width: 130px;

            @media(max-width: $md3+px) {}

            @media(max-width: $md6+px) {
                width: 110px;
            }

            a {
                width: 100%;
                display: block;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &Links {
            display: flex;
            gap: 75px;
            font-family: $mont;
            font-size: 17px;
            font-weight: 400;
            line-height: 150%;

            @media(max-width: $md3+px) {
                display: none;
            }

            .linkItem {
                position: relative;
                color: $white;
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 5px;

                &:hover {
                    color: rgba($color: $white, $alpha: 0.8)
                }
            }

            .listWrap {
                position: relative;

                &:hover {
                    .subRoutes {
                        opacity: 1;
                        pointer-events: visible;
                    }
                    svg {
                        transform: rotate(180deg);
                    }
                }

                svg {
                    transition: all .3s;
                }

                .subRoutes {

                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

                    position: absolute;
                    background: #C62230;
                    white-space: nowrap;
                    left: -10px;
                    top: 23px;
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding-top: 5px;

                    opacity: 0;
                    pointer-events: none;

                    transition: all .3s;

                    a {
                        padding: 5px 10px;
                    }
                }
            }

        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            .burger {
                @media(min-width: 1221px) {
                    display: none;
                }

                cursor: pointer;
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    border-radius: 10px;
                    transition: all .3s;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    border-radius: 10px;
                    transition: all .3s;
                }

                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

:global(.Dropdown-control) {
    font-family: $audio !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 17px !important;
    background-color: transparent !important;
    border: none !important;
    color: $white !important;
    text-transform: uppercase !important;
    padding-right: 30px !important;
    cursor: pointer !important;

    &:hover {
        box-shadow: none;
    }
}

:global(.Dropdown-menu) {
    font-family: $audio !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

:global(.Dropdown-arrow) {
    border-color: $white transparent transparent !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

:global(.is-open .Dropdown-arrow) {
    border-color: transparent transparent $white !important;
}