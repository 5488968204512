@import '../../../styles/var';

.interested {
    position: relative;

    .wrap {
        position: relative;
        z-index: 4;
        width: 100%;
        max-width: 698px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .circle {
            position: relative;
            z-index: 1;
            width: 100%;
        }

        .content {
            position: absolute;
            z-index: 2;
            padding: 30px;

            .title {
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media(max-width: $md6+px) {
                    gap: 5px;
                }

                span {
                    color: $white !important;
                }

                .sub_02 {
                    max-width: 600px;

                    @media(max-width: $md4+px) {
                        max-width: 450px;
                    }
                }
            }

            .text {
                margin: 30px 0 40px;

                @media(max-width: $md4+px) {
                    margin-top: 15px;
                }

                @media(max-width: $md6+px) {
                    max-width: 250px;
                    margin: 15px auto 0;
                }
            }

            .btn {
                display: flex;
                justify-content: center;

                @media(max-width: $md6+px) {
                    display: none;
                }
            }
        }
    }

    .item {
        position: absolute;
        z-index: 3;

        @media(max-width: $md4+px) {
            display: none;
        }

        &_01 {
            width: 425px;
            top: 100px;
            left: -130px;
        }

        &_02 {
            width: 138px;
            top: -53px;
            right: 130px;
            transform: scaleX(-1);            
        }

        &_03 {
            width: 117px;
            bottom: 240px;
            right: 35px;
        }

        img {
            width: 100%;
        }
    }
}