@import '../../../styles/var';

.consultations {
    .head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;

        @media(max-width: $md5+px) {
            flex-direction: column;
            align-items: unset;
            gap: 30px;
        }

        .title {
            @media(max-width: $md5+px) {
                text-align: center;
            }
        }

        .text_01 {
            text-align: right;
            width: 370px;
            padding-bottom: 15px;

            @media(max-width: $md3+px) {
                padding-bottom: 10px;
            }

            @media(max-width: $md4+px) {
                padding-bottom: 5px;
            }

            @media(max-width: $md5+px) {
                padding-bottom: 0;
                text-align: left;
                width: 100%;
            }
        }
    }

    .text_02 {
        margin-top: 33px;
        max-width: 334px;

        @media(max-width: $md3+px) {
            margin-top: 45px;
        }

        @media(max-width: $md5+px) {
            margin-top: 15px;
            max-width: 100%;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 334px);
        justify-content: center;
        gap: 120px 160px;
        margin-top: 50px;

        @media(max-width: $md3+px) {
            gap: 120px;
        }

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 236px);
            gap: 80px 40px;
        }

        @media(max-width: $md5+px) {
            gap: 80px 20px;
        }

        @media(max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px 30px;
        }

        @media(max-width: $md6+px) {
            gap: 28px 18px;
        }

        .cardWrap {
            &:nth-child(1) {
                .card {
                    transform: rotate(-15deg);

                    @media(max-width: $md4+px) {
                        transform: rotate(-15deg) translateX(-70px);
                    }
                }
            }

            &:nth-child(2) {
                .card {
                    @media(max-width: $md4+px) {
                        transform: translateY(-30px);
                    }
                }
            }

            &:nth-child(3) {
                .card {}
            }

            &:nth-child(4) {
                .card {
                    transform: rotate(15deg);

                    @media(max-width: $md4+px) {
                        transform: rotate(15deg) translateX(40px) translateY(-30px);
                    }
                }
            }

            .card {
                padding: 17px;
                background-image: url('../../../assets/img/HomePage/Consultations/card_bg.avif');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: all .3s;

                @media(max-width: $md4+px) {
                    padding: 12px;
                }

                @media(max-width: 600px) {
                    transform: none !important;
                }

                @media(max-width: $md6+px) {
                    padding: 8px;
                }

                &Image {
                    width: 100%;
                }

                &Text {
                    margin-top: 13px;
                    text-align: center;
                    
                    @media(max-width: $md6+px) {
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}