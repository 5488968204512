@import '../../../styles/var';

.structure {
    position: relative;

    .wrap {
        position: relative;

        .textSwiperWrap {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 575px;
            width: 100%;
            max-width: 600px;

            @media(max-width: $md3+px) {
                position: relative;
                left: unset;
                max-width: 100%;
                margin-bottom: 40px;
            }

            @media(max-width: $md5+px) {
                margin-bottom: 25px;
            }

            .title {
                margin-bottom: 30px;

                @media(max-width: $md4+px) {
                    margin-bottom: 50px;
                }

                @media(max-width: $md6+px) {
                    margin-bottom: 40px;
                }
            }

            .textCard {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
        }

        .nav {
            position: absolute;
            z-index: 5;
            display: flex;
            gap: 36px;
            right: 0;
            top: 260px;

            @media(max-width: $md3+px) {
                top: unset;
                bottom: 200px;
            }

            @media(max-width: $md5+px) {
                position: relative;
                bottom: unset;
                left: unset;
                margin-top: 20px;
                margin-bottom: 15px;
                justify-content: flex-end;
            }

            &Btn {
                width: 50px;
                height: 50px;
                background: #C62432;
                border: 1px solid #C62432;
                border-radius: 100%;
                transition: all .3s;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                @media(max-width: $md6+px) {
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    opacity: 0.7;
                }

                &.inactive {
                    border-color: #fff;
                    background: transparent;
                    opacity: 1 !important;
                }

                &.right {
                    transform: rotate(180deg);
                }

                .arrow {
                    width: 30px;

                    @media(max-width: $md6+px) {
                        width: 22px;
                    }
                }
            }
        }
    }

    .swiperWrap {
        position: relative;
        width: 174px;

        @media(max-width: $md4+px) {
            width: 120px;
        }

        @media(max-width: $md5+px) {
            width: 100%;
            max-width: 480px;
            margin: 0 auto;
        }

        .card {
            transition: all .3s;
            width: 100%;

            * {
                transition: all .3s;
            }

            &Image {
                width: 100%;
            }
        }
    }
}

:global(.aiStructureSwiper) {
    overflow: visible !important;
    height: 485px !important;

    @media(max-width: $md4+px) {
        height: 295px!important;
    }

    @media(max-width: $md5+px) {
        height: auto !important;
        overflow: hidden !important;
    }

    @media(max-width: $md6+px) {
        overflow: visible !important;
    }
}

:global(.aiStructureSwiperSlide) {
    height: auto !important;
    width: 174px !important;
    transition: all .3s !important;
    margin-top: auto;
    opacity: 0.5;

    @media(max-width: $md4+px) {
        width: 120px!important;
    }

    @media(max-width: $md5+px) {
        width: 100% !important;
        opacity: 1 !important;
        margin-top: 0 !important;
    }
}

:global(.aiStructureSwiperSlideActive) {
    width: 525px !important;
    opacity: 1;

    @media(max-width: $md4+px) {
        width: 320px!important;
    }

    @media(max-width: $md5+px) {
        width: 100% !important;
    }
}