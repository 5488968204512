@import '../../../styles/var';

.button {
    display: block;
    position: relative;
    width: 287px;
    height: 80px;
    cursor: pointer;

    @media(max-width: $md6+px) {
        height: 65px;
        width: 248px;
    }

    &:active {
        .circle {
            transform: scale(1) !important;
        }
    }

    .text {
        position: absolute;
        z-index: 2;
        top: 16px;
        color: $white;
        font-family: $mont;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        @media(max-width: $md6+px) {
            font-size: 14px;
            top: 12px;
        }
    }

    .arrow {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 251px;

        @media(max-width: $md6+px) {
            width: 219px;
        }
    }

    .circle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: #1E0204;
        transition: .1s ease;
        pointer-events: none;

        @media(max-width: $md6+px) {
            width: 65px;
            height: 65px;
        }

        &.red {
            background: #C62432;
        }
    }

    &.black {
        .text {
            color: #1E0204;
        }

        .arrow {
            path {
                fill: #1E0204;
            }
        }
    }
}