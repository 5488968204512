@import '../../../styles/var';

.wrap {
    background: #311719;
}

.consultations {
    position: relative;
    padding: 40px 0 60px;

    @media(max-width: $md4+px) {
        padding: 30px 0 50px;
    }

    @media(max-width: $md6+px) {
        padding: 30px 0 40px;
    }

    .title {
        text-align: right;

        @media(max-width: $md5+px) {
            font-size: 44px;
        }

        @media(max-width: $md6+px) {
            font-size: 30px;
        }
    }

    .subtitle {
        text-align: right;
    }

    .text {
        margin-top: 30px;
        max-width: 525px;
    }
}